<template>
  <div>
      <h2>Photos</h2>
      <section>
        <img v-if="modalUrl" @click="setModalImage(null)" :src="getImageUrl(modalUrl)" class="imageModal">
        <div>
          <div class="image-list" >
            <img  @click="setModalImage(imageUrl)" v-for="imageUrl in images" :key="imageUrl" :src="getImageUrl(imageUrl)" />
          </div>
        </div>
      </section>
  </div>
</template>

<script>

const images = [
  "boston-party.jpeg",
  "boston-party-2.jpeg",
  "face-masks.jpeg",
  "fire-pit.jpeg",
  "plane.jpeg",
  "signed-for-departed-soles.jpeg",
  "snow-pup.jpeg",
  "times-square.jpeg",
  "southern-pines.jpeg",
  "engagement.jpeg",
  "dog.jpeg",
  "beach.jpeg",
  "glasses.jpeg",
  "running_glasses.jpeg",
  "vermont_marathon.jpeg",
  "lights.jpeg",
  "new-years.jpeg",
  "dallas.jpeg",
  "badlands.jpeg",
  "post-boston.jpeg",
  "pride.jpeg",
  "silly-hats.jpeg",
  "s-i-wedding.jpeg",
  "planter.jpg",
  "80s-night.jpeg",
  "icecream.jpg",
  "aminals.jpeg",
  "proposal1.jpeg",
  "proposal2.jpeg",
]



export default {
  name: 'Photos',
  methods: {
    getImageUrl(pic) {
      return require('../assets/'+pic)
    },
    setModalImage(url) {
      console.log(url);
      this.modalUrl = url;
    }
  },
  data() {
    return {
      images,
      modalUrl: null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  max-width: 30em;
}
.image-list {
  column-count: 1;
  column-gap: 2px;
  margin: 1em;
}
.image-list img {
  flex: 1;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.imageModal {
  object-fit: contain;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0.5, 0.5, 0.5, 0.8);
}
@media (min-width: 500px) {

  .image-list {
    column-count: 2;
  }
}
@media (min-width: 800px) {

  .image-list {
    column-count: 3;
  }
}
</style>
