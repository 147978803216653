<template>
    <nav v-bind:class="{inverted}" >
        <div class="full-links">
            <router-link active-class="active" exact to="/"><span>HOME</span></router-link>
            <div class="days-left">
                <span class="days-left-number">{{daysUntilWedding}}</span>
                <span class="days-left-text">days left</span>
            </div>
            <div class="links">
                <router-link active-class="active" to="/photos"><span>PHOTOS</span></router-link>
                <router-link active-class="active" to="/thebigday"><span>THE DAY</span></router-link>
                <router-link active-class="active" to="/updates"><span>UPDATES</span></router-link>
                <router-link active-class="active" to="/thecouple"><span>THE COUPLE</span></router-link>
                <router-link active-class="active" to="/registry"><span>REGISTRY</span></router-link>
            </div>
        </div>
        <div class="collapsed-links">
            <router-link active-class="active" exact to="/"><span>HOME</span></router-link>
            <div class="days-left">
                <span class="days-left-number">{{daysUntilWedding}}</span>
                <span class="days-left-text">days left</span>
            </div>
            <div :class="{collapsed, links: true}" >
                <router-link active-class="active" to="/photos"><span>PHOTOS</span></router-link>
                <router-link active-class="active" to="/thebigday"><span>THE DAY</span></router-link>
                <router-link active-class="active" to="/updates"><span>UPDATES</span></router-link>
                <router-link active-class="active" to="/thecouple"><span>THE COUPLE</span></router-link>
                <router-link active-class="active" to="/registry"><span>REGISTRY</span></router-link>
            </div>
            <div :class="{menuButton:true}" @click="toggleCollapse">
                <font-awesome-icon v-if="collapsed" icon="fa-solid fa-bars" />
                <font-awesome-icon v-else icon="fa-solid fa-xmark" />
            </div>
        </div>
    </nav>
</template>

<script>

export default {
  name: 'Nav',
  props: {
      inverted: Boolean
  },
  data() {
      const millisUntilWedding = Date.parse("10/1/2022") - new Date();
      console.log("millisUntilWedding", millisUntilWedding)
      const daysUntilWedding = Math.floor(millisUntilWedding / (1000*60*60*24))
      console.log("daysUntilWedding", daysUntilWedding)
      return {
          collapsed: true,
          daysUntilWedding
        }
  },
  methods: {
      toggleCollapse() {
          this.collapsed = !this.collapsed;
      }
  }
}
</script>

<style>
nav {
    padding: 0.5em;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}
nav .collapsed-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}
nav .links {
    display: flex;
}
nav .collapsed-links .links {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

}
nav .collapsed.links {
    display: none;
}
nav .menuButton {
    cursor: pointer;
    font-size: 2em;
}
nav .full-links {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

nav.inverted a {
    background: black;
    color: white;
    border: 2px solid black;
    padding: 0.5em 1em;
    margin: 0.2em;
    text-decoration: none;
}
nav.inverted a span {
    padding: 0 0.2em;
    color: inherit;
    border-bottom: 2px solid transparent;
}
nav.inverted a.active span {
    border-bottom: 2px solid white;
}
nav.inverted a:hover {
    background: lightgrey;
    color: black;
    border: 2px solid black;
}
nav.inverted a.active:hover span {
    border-bottom: 2px solid black;
}
nav.inverted .menuButton {
    color: black;
}


nav a {
    background: white;
    color: black;
    border: 2px solid white;
    padding: 0.5em 1em;
    margin: 0.2em;
    text-decoration: none;
}
nav a span {
    padding: 0 0.2em;
    color: inherit;
    border-bottom: 2px solid transparent;
}
nav a.active span {
    border-bottom: 2px solid black;
}
nav a:hover {
    background: grey;
    color: white;
    border: 2px solid white;
}
nav a.active:hover span {
    border-bottom: 2px solid white;
}
nav .menuButton {
    color: white;
}

.days-left {
    position: absolute;
    left: 6em;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    margin: 0.2em;
}
.days-left > .days-left-number {
    font-weight: bold;
    font-size: 1.1em;
    padding: 0.45em;
    background: rgba(0, 0, 0, 0.6);
}
.days-left > .days-left-text {
    font-size: 0.8em;
    padding: 0.2em;
    background: rgba(0, 0, 0, 0.4);
}
@media (min-width: 800px) {
    nav .collapsed-links {
        display: none;
    }
    nav .full-links {
        display: flex;
    }
}
</style>