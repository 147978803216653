<template>
  <div>
    <Nav inverted />
    <div class="content">
      <h1>Save The Date</h1>
      <section>
        <div>
          <div>
            <p>October 1st, 2022, 5:15pm to 10:00pm</p>
            <p>Departed Soles, 150 Bay St #2a, Jersey City, NJ 07302</p>
          </div>
          <div>
            <p>We have a hotel block with the Residence Inn in Jersey City, only a few blocks from the venue! The last day to book a room is August 30th. </p>
            <p><a href="https://www.marriott.com/events/start.mi?id=1649959701385&key=GRP">Book a room with our discount</a></p>

          </div>
          <div class='atcb'>
            <script type="application/ld+json">
                {{eventJson}}
            </script>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Nav from './Nav'
import { atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.min.css';

const eventSchema = {
      "event": {
        "@context":"https://schema.org",
        "@type":"Event",
        "name":"Emily and Trevor's Wedding",
        "description":"Come enjoy the union of Emily Ashbolt and Trevor Creed! \nhttps://emilyfortrevorandever.com",
        "startDate":"2022-10-01T17:15",
        "endDate":"2022-10-01T22:00",
        "location":"Departed Soles, 150 Bay St #2a, Jersey City, NJ 07302",
      },
      "label":"Add to Calendar",
      "options":[
        "Apple",
        "Google",
        "iCal",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
        "Yahoo"
      ],
      "timeZone":"America/New_York",
      "trigger":"click",
      "iCalFileName":"Reminder-Event"
    }
const eventJson = JSON.stringify(eventSchema);

export default {
  name: 'Updates',
  components: {
    Nav
  },
  mounted() {
    atcb_init();
  },
  data() {
    return {
      eventJson
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  max-width: 30em;
}
.update-list-item {
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1em;
}
.update-list-item > * {
  padding: 1em 0.5em;
  align-self: stretch;
  border: 4px solid lightgrey;
}
.update-list-item .date {
  flex-grow: 1;
  min-width: 5%;
  white-space: nowrap;
  background: lightgrey;
}
.update-list-item .img-container {
  background: lightgrey;
}
.update-list-item img {
  width: 100%;
  max-width: 10em;
}
@media (min-width: 800px) {
  .update-list-item {
    flex-direction: row;
    text-align: left;
  }
  .update-list-item .img-container {
    width: 8em;
    flex-grow: 0;
  }
}
</style>
