import Vue from 'vue'
import VueRouter from 'vue-router'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faXmark, faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import Home from './components/Home.vue'
import TheBigDay from './components/TheBigDay.vue'
import TheCouple from './components/TheCouple.vue'
import Photos from './components/Photos.vue'
import Updates from './components/Updates.vue'
import Registry from './components/Registry.vue'
import SaveTheDate from './components/SaveTheDate.vue'


import './main.css'


library.add(faBars, faXmark, faExternalLink)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter)
Vue.config.productionTip = false

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/thebigday',
    component: TheBigDay
  },
  {
    path: '/thecouple',
    component: TheCouple
  },
  {
    path: '/photos',
    component: Photos
  },
  {
    path: '/updates',
    component: Updates
  },
  {
    path: '/registry',
    component: Registry
  },
  {
    path: '/savethedate',
    component: SaveTheDate
  }
]

const router = new VueRouter({  
  mode: 'history',
  routes
})

new Vue({
  router
}).$mount('#app')
