<template>
  <div>
    <Nav inverted />
    <div class="content">
      <h1>The Big Day</h1>
      <section class="lefttext">
        <vue-markdown class="md" :source="basicInfoMarkdown" />
          <img src="../assets/local_map.jpeg" />
        <vue-markdown class="md" :source="hotelsMarkdown" />
        <vue-markdown class="md" :source="travelMarkdown" />
        <vue-markdown class="md" :source="thingsToDoMarkdown" />
      </section>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'
import Nav from './Nav.vue'

const basicInfoMarkdown = `

**Friday, September 30th**  
**8:30pm** - Zeppelin Beer Hall  
Come grab a drink with us if you're in town! We'll be going to bed at 10:30, don't buy us shots, but we'd love to see you!

**Saturday, October 1st**  
**5:15pm** - Departed Soles Brewing Company  
The party! Be on time so you don't miss our epic first dance.  
**10pm** - Newark Avenue  
We hit the bars! 

**Sunday, October 2nd**  
**10am** - [J. Owen Grundy Park](https://www.google.com/maps/place/J+Owen+Grundy+Park/@40.7162193,-74.0340489,17z/data=!3m1!4b1!4m5!3m4!1s0x89c250a9bc86b3f5:0x19ec77bf45b83371!8m2!3d40.7162153!4d-74.0318602)  
It's BYOB (bring your own breakfast sandwich), and we'll have coffee for you. Say hello, meet Gemini, and nurse your hangover before we fly off into the sunset!

`
const hotelsMarkdown = `
  ## Lodging
  We booked a hotel block, look out for the link! It's a great spot that's close to the venue.
  If you'd like to stay elsewhere,
  there are plenty of hotels nearby! We're planning for everyone staying in the area to be able to 
  walk to the event, so ideally people would leave their cars parked at the hotel.
`

const travelMarkdown = `
  ## Travel
  If you're flying in, Newark is the easiest airport to fly to. It's a short drive, and there's public transit
  that goes right into the heart of JC. 
`

const thingsToDoMarkdown = `
  ## Making the most of it!
  There are so many things to do in JC! 

  ### Newark Ave and Grove Street
  If you're looking for bars, restaurants, or tattoo parlors, Newark Ave and Grove Street are chock full. 

  ### NYC
  It's right there! Take the PATH train into Manhattan to get your fill of big-city sights.
  With routes to WTC in southern Manhattan and stops in Midtown, 
  you can get to many places in New York with a single connection or none at all.

  ### Liberty State Park
  This park is directly south of downtown JC, and the boardwalk offers a beautiful view of the NY skyline.
  If you're a runner, there are several easy routes around the park. 
`

export default {
  name: 'TheBigDay',
  components: {
    VueMarkdown,
    Nav
  },
  data() {
    return {
      basicInfoMarkdown,
      hotelsMarkdown,
      travelMarkdown,
      thingsToDoMarkdown
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
