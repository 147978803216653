<template>
  <div>
    <Nav inverted />
    <div class="content">
      <h1>Registry</h1>
      <section>
        <p>
            We're using a service called My Registry, which isn't affiliated with any particular site. 
            You just visit the links in the list, purchase any of the items, 
            and mark that you've purchased it on our registry.
        </p>    
        <a :href="registry" target="_blank">Our Registry <font-awesome-icon icon="fa-solid fa-external-link" /></a>
      </section>
    </div>
  </div>
</template>

<script>
import Nav from './Nav.vue'



export default {
  name: 'Registry',
  components: {
    Nav
  },
  data() {
    return {
      registry: "https://www.myregistry.com/wedding-registry/trevor-creed-and-emily-ashbolt-jersey-city-nj/3203311/giftlist"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
