<template>
  <div>
    <Nav />
    
    <div class="content">
      <section class="full-screen">
        <div class="pic-container">
          <img src="../assets/homepage-2.jpeg" />
        </div>
        <div class="md inverted sub-header-content">
          <h1>We're getting married!</h1>
          <div class="sub-header-info">
            <p>#EmilyForTrevorAndEver</p>
            <p>10.1.22</p>
            <p>Jersey City</p>
          </div>
        </div>
      </section>
      <section>
        <h2>How well do you know the couple?</h2>
        <p>Choose your team and take the quiz</p>
        <div class="link-row">
          <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScTv-cUN9SPQwQUq-wd4HEfa8zi5SRE9AyTa0wvZH28umRyYg/viewform?usp=sf_link">Team Emily</a>
          <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScMgkqo-aPEpzdK272RO2017azoooKjqtb7aMFzYvOVCe9qPQ/viewform?usp=sf_link">Team Trevor</a>
        </div>
      </section>
      <section>
        <vue-markdown class="md" :source="markdown2" />
        <div class="two-panel">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1666.0049450163162!2d-74.0385620834765!3d40.72103466167179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c250ada149a34f%3A0x87c10ab6d76c935a!2sDeparted%20Soles%20Brewing%20Company!5e0!3m2!1sen!2sus!4v1644513222738!5m2!1sen!2sus" 
            width="600" 
            height="450" 
            style="border:0;" 
            allowfullscreen="" 
            loading="lazy"
            class="panel"></iframe>
          <div class="panel">
            <h2>Departed Soles Brewing Company</h2>
            <p>150 Bay St #2a</p>
            <p>Jersey City, NJ 07302</p>
            <a href="https://www.departedsoles.com/">departedsoles.com</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// eslint-disable no-useless-escape
import VueMarkdown from 'vue-markdown-render'
import Nav from './Nav.vue'
const markdown1 = `
\\#EmilyForTrevorAndEver

10.1.22

Jersey City

`

const markdown2 = `
## Your favorite couple is making it happen

We hope you can join us for a romantic rager at Departed Soles Brewing in Jersey City!

5:15pm to 9:30pm

Come hydrated
`

export default {
  name: 'Home',
  components: {
    VueMarkdown,
    Nav
  },
  data() {
    return {
      markdown1,
      markdown2
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
section .md.sub-header-content {
  
  width: 100%;
  color: white;
}
section .md.sub-header-content h1 {
  margin: 1em auto;
  text-align: center;
}
section .md.sub-header-content .sub-header-info {
  margin: 0 auto;
  text-align: center;
}
.sub-header-content p {
  margin: 0.3em;
  font-size: 0.9em;
}
.two-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.panel {
  padding: 0.5em;
  max-width: 100%;
  box-sizing: border-box;
}
.link-row {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.link-row a {
  margin: 0.7em;
}
@media (min-width: 800px) {
  .link-row {
    flex-direction: row;
  }
  .two-panel {
    flex-direction: row;
  }
  .panel {
    max-width: calc(50% - 1em);
  }
  section .md.sub-header-content {
    position: relative;
    top: 50vh;
    width: 100%;
    color: white;
  }
}
</style>
