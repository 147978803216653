<template>
  <div>
    <Nav inverted />
    <div class="content">
      <h1>Updates</h1>
      <section>
        <div class="list-container">
          <div class="update-list-item" v-for="update in updates" :key="update.date">
            <span class="date">{{update.date}}</span>
            <span>{{update.message}}</span>
            <div class="img-container"><img :src="getImageUrl(update.img)" /></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Nav from './Nav'

const updates = [
  {
    date: "2.6",
    message: "The contract is signed over a beer, Departed Soles in Jersey City is the official location for the reception!",
    img: "signed-for-departed-soles.jpeg"
  },
  {
    date: "2.19",
    message: "Our engagement party! We had a blast and then absolutely tore up the floor at Ned Devine's.",
    img: "engagement-party.jpeg"
  },
  {
    date: "4.14",
    message: "We booked the hotel block and bought honeymoon tickets! Making moves!",
    img: "snug-harbor.jpeg"
  }
]

export default {
  name: 'Updates',
  components: {
    Nav
  },
  methods: {
    getImageUrl(pic) {
      return require('../assets/'+pic)
    }
  },
  data() {
    return {
      updates
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  max-width: 30em;
}
.list-container {
  display: flex;
  flex-direction: column-reverse;
}
.update-list-item {
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1em;
}
.update-list-item > * {
  padding: 1em 0.5em;
  align-self: stretch;
  border: 4px solid lightgrey;
}
.update-list-item .date {
  flex-grow: 1;
  min-width: 5%;
  white-space: nowrap;
  background: lightgrey;
}
.update-list-item .img-container {
  background: lightgrey;
}
.update-list-item img {
  width: 100%;
  max-width: 10em;
}
@media (min-width: 800px) {
  .update-list-item {
    flex-direction: row;
    text-align: left;
  }
  .update-list-item .img-container {
    width: 8em;
    flex-grow: 0;
  }
}
</style>
