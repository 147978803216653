<template>
  <div>
      <Nav inverted />
      <div class="content">
        <h1>Photos</h1>
        <section>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/v7yLDd8wWMI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/wdVQbSvGa8w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </section>
        <section>
          <img v-if="modalUrl" @click="setModalImage(null)" :src="getImageUrl(modalUrl)" class="imageModal">
          <div>
            <div class="image-list" >
              <img  @click="setModalImage(imageUrl)" v-for="imageUrl in images" :key="imageUrl" :src="getImageUrl(imageUrl)" />
            </div>
          </div>
        </section>
      </div>
  </div>
</template>

<script>

import Nav from './Nav'
const images = [
]



export default {
  name: 'Photos',
  methods: {
    getImageUrl(pic) {
      return require('../assets/'+pic)
    },
    setModalImage(url) {
      console.log(url);
      this.modalUrl = url;
    }
  },
  data() {
    return {
      images,
      modalUrl: null
    }
  },
  components: {
    Nav
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  max-width: 30em;
}
.image-list {
  column-count: 1;
  column-gap: 2px;
  margin: 1em;
}
.image-list img {
  flex: 1;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.imageModal {
  object-fit: contain;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0.5, 0.5, 0.5, 0.8);
}
@media (min-width: 500px) {

  .image-list {
    column-count: 2;
  }
}
@media (min-width: 800px) {

  .image-list {
    column-count: 3;
  }
}
</style>
