<template>
    <div>
      <h2>Our Story</h2>
      <section class="lefttext">
        <vue-markdown class="md" :source="markdown" />
        <img src="../assets/successkid.jpeg" />
      </section>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'

const markdown = `

"She's not your girlfriend"
\\- Matt Zeiger, April 2017


One blue-haired physics student at Northeastern, one long-haired software developer who's new in town; two hearts, 
joined together on the dance floor of Ned Devine's.

Shout out to Matt Zeiger and Kelly McKeon, who were there that fateful night. 

A month later, after a whirlwind romance that _we both knew wasn't gonna happen_, Emily moved to South Dakota. We parted ways amiably, knowing that we were pleasant but ultimately
brief blips in each other's lives.

We couldn't stop talking, however. In November of 2017 Trevor went to visit Emily in South Dakota, and by New Year's Eve, we were official.

In 2019, Emily is accepted to Stevens University as a Biomedical Engineering PHD student. In August of that year,
we move in together in a tiny apartment in Hoboken, and embark on a new domestic journey with Coco the cat.

New jobs, apartments, pets, and global pandemics ensue. 

On November 27th, 2021, several days after Thanksgiving and four years after Trevor's trip to SD, Trevor asked Emily to marry him on the banks of the Charles River in Boston.
She says yes, obviously. We are to be wed. 

`

export default {
  name: 'OurStory',
  components: {
    VueMarkdown
  },
  data() {
    return {
      markdown
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
