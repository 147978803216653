<template>

    <div>
        <Nav inverted />
        <div class="content">
            <h1 class="couple-title"><span>Creed</span><span>+</span><span>Ashbolt</span></h1>
            <OurStory />
            <CouplePhotos />
        </div>
    </div>
</template>

<script>
import Nav from './Nav'
import CouplePhotos from './CouplePhotos.vue';
import OurStory from './OurStory.vue';

export default {
    name: 'TheCouple',
    components: {
        CouplePhotos,
        OurStory,
        Nav
    }
}
</script>

<style scoped>
.couple-title {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}
@media (min-width: 800px) {
    .couple-title {
        margin-left: 2.5em;
        margin-right: 2.5em;
        flex-direction: row;
    }
}
</style>